import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { radioButtonOn, eyeOutline, checkmarkCircleOutline, checkmarkDoneCircle } from "ionicons/icons";
import { IconCheckCircle, IconCheckCircleOutline } from "@aws-amplify/ui-react";
import { DeleteButton, EditButton } from "../";
import { useTimesheets, useUser } from "../../hooks";
import { AuthUser, Contact, Timesheet, TimesheetStatus, TradeType } from "../../models";
import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
interface TimesheetItemProps {
  timesheet: Timesheet;
  onEdit: () => void;
  onApproval?: () => void;
  onDelete?: () => void;
  customStyle?: any;
  withCheckboxes?: boolean;
  onUpdate?: () => void;
  hideProject?: boolean;
}

export const TimesheetItem: React.FC<TimesheetItemProps> = (props) => {
  // User
  const { security } = useUser();

  const { deleteTimesheet, toggleStatus, toggleBillingStatus } = useTimesheets();

  const [ billingStatus, setBillingStatus ] = useState<TimesheetStatus>(props.timesheet.billing_status as TimesheetStatus);

  const [ status, setStatus ] = useState<TimesheetStatus>(props.timesheet.status as TimesheetStatus);

  const [userDisplayName, setUserDisplayName] = useState<string>();

  // Timestamps
  const start = new Date(props.timesheet.date + "T" + props.timesheet.start);
  
  const end = new Date(props.timesheet.date + "T" + props.timesheet.end);

  const startDate = start.toLocaleDateString("en-US", {weekday: 'short', year: '2-digit', month: 'short', day: 'numeric'});

  let addoneday = false;

  if (end.getTime() < start.getTime()) {
    addoneday = true;
  }

  // Hours
  const hduration = (end.getTime() + (addoneday ? 1*24*60*60*1000 : 0) - start.getTime()) / 1000;
  const fullminutes = Math.floor(hduration / 60) - (props.timesheet.lunch ? 30 : 0);
  const hours = Math.floor(fullminutes / 60);
  const minutes = (fullminutes % 60);
  const shours = hours + 'h' + (minutes % 60) + 'min';

  // Status
  let statusColor = "primary";
  if (props.timesheet?.status === TimesheetStatus.REJECTED)
    statusColor = "danger";
  if (props.timesheet?.status === TimesheetStatus.APPROVED)
    statusColor = "medium";

  console.log(props.timesheet);

  let greyedOutStyle = {
    backgroundColor: "#CCCCCC"
  };

  let ungreyedOutStyle = {

  };

  let bgStyle = props.timesheet.billing_status === TimesheetStatus.BILLED ? greyedOutStyle : ungreyedOutStyle;

  return (
    <IonItem>
      <IonGrid>
        <IonRow style={props.customStyle ? {...props.customStyle, ...bgStyle} : {...bgStyle}}>
          {/* Job details */}
          <IonCol sizeSm="1">
            {props.withCheckboxes &&
              <IonCheckbox name="checkedTimesheets" value={props.timesheet.id}>
              </IonCheckbox>
            }
            <IonIcon
              icon={checkmarkDoneCircle}
              onClick={() => toggleStatus(props.timesheet.id).then((new_status) => setStatus(new_status as TimesheetStatus)).then(() => props.onUpdate && props.onUpdate())}
              color={
                [TimesheetStatus.APPROVED].includes(status as TimesheetStatus)
                   ? "success"
                   : "medium"
                   //: [TimesheetStatus.UNAPPROVED].includes(props.timesheet.status as TimesheetStatus) ? "medium" : "medium"
              }
            />
            
          </IonCol>

          {!security.isField && 
            <IonCol sizeSm="3">
              {props.timesheet.employee?.firstName ? props.timesheet.employee?.firstName + (props.timesheet.employee?.lastName ? " " + props.timesheet.employee?.lastName : "") : props.timesheet.employee?.username}
            </IonCol>
          }
          
          {!props.hideProject &&
            <IonCol sizeSm="1">
              <IonItem>
                <IonText>
                  {props.timesheet.project?.code?.toUpperCase() +
                    "-" +
                    props.timesheet.project?.number}
                </IonText>
              </IonItem>
            </IonCol>
          }

          <IonCol sizeSm="1">
            <IonItem>
              <IonText>
                {shours}
              </IonText>
            </IonItem>
          </IonCol>

          <IonCol sizeSm="2">
            <IonItem>
              <IonText>
                {startDate}
              </IonText>
            </IonItem>
          </IonCol>

          <IonCol sizeSm="1">
            <IonItem>
              <IonText>
                <p>
                  <IonIcon
                    icon={radioButtonOn}
                    color={
                      props.timesheet.trade === TradeType.PLUMBING
                        ? "secondary"
                        : "tertiary"
                    }
                  />
                  {" " + props.timesheet.trade}
                </p>
              </IonText>
              <IonLabel></IonLabel>
            </IonItem>
          </IonCol>

          {/* Hours details */}
          <IonCol sizeSm="2">
            <IonItem lines="none">
              {/* <IonLabel position="stacked">Details</IonLabel> */}
              <IonTextarea readonly value={props.timesheet.details} />
            </IonItem>
          </IonCol>

          
          {/* <IonCol sizeSm="2">
            <IonButton fill="clear" color={statusColor}>
              {props.timesheet?.status}
            </IonButton>
          </IonCol> */}

          {/* Edit button */}
          <IonCol sizeSm="1" className="ion-text-right no-print">
            <EditButton onEdit={() => props.onEdit()} />
            <DeleteButton onDelete={() => {
              deleteTimesheet(props.timesheet.id).then(() => {
                if (props.onDelete) {
                  props.onDelete();
                }                
              });
            }} />
            {(security.isAdmin || security.isOffice) && props.onApproval && 
              <IonButton onClick={() => {
                //console.log(props.onApproval);
                if (props.onApproval) {
                  props.onApproval();
                }
              }}>
                <IonIcon slot="icon-only" icon={checkmarkCircleOutline} />
              </IonButton>}
          </IonCol>
          
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};
