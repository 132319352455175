import { IonItem, IonPopover, IonLabel, IonInput } from "@ionic/react";
import { useEffect, useState } from "react";
import { useProjectSearch } from "../../hooks/useProjectSearch";
import { Project } from "../../models";
import { ProjectLabel } from "../Items";

interface JobInputProps {
  id: null | string | undefined;
  hideLable?: boolean;
  onSelect: (id: string) => void;
  onBlur?: (value?: string) => void;
  onType?: (value?: string) => void;
}

export const JobInput: React.FC<JobInputProps> = (props) => {
  // Proload lates
  const { keyword, projects, unarchivedProjects, setKeyword } = useProjectSearch();

  // Open popover
  const [isOpen, setIsOpen] = useState(false);

  // Event
  const [event, setEvent] = useState<Event | undefined>();

  // Handle job change
  const selectJob = (project: Project) => {
    // Set keyword to match
    let newKeyword =
      "" +
      project?.code?.toUpperCase() +
      project?.number?.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    setKeyword(newKeyword);

    if (props.id !== project.id) {
      //Set state
      props.onSelect(project.id);
    }

    setIsOpen(false);
  };

  useEffect(() => {
    if (!props.id) setKeyword(undefined);
  }, [props.id]);

  return (
    <IonItem>
      {!props.hideLable && <IonLabel position="stacked">Job PO</IonLabel>}

      <IonInput
        type="text"
        value={keyword}
        onIonChange={(e) => {
           // Capitalize
            console.log(keyword);
            setKeyword(e.detail.value ? (e.detail.value as string).toUpperCase() : '');
            if (props.onType) {
               props.onType(e.detail.value!.toUpperCase());
            }
          }
        }
        onIonInput={(e) => {
          if (e.detail.data && (keyword === "" || keyword === undefined))
            setKeyword((e.detail.data as string).toUpperCase());
          if (props.onType && e.detail.data!) {
            props.onType(e.detail.data!.toUpperCase());
          }
          // Open popover when inputing
          setIsOpen(true);
        }}
        onFocus={(e) => {
          // Add event
          setEvent(e.nativeEvent);
        }}
        onBlur={() => {
          console.log('kkkeyword');
          console.log(keyword);
          if (props.onBlur) {
            props.onBlur(keyword);
          }
        }}
      />

      {/* Propover to show */}
      <IonPopover
        isOpen={isOpen}
        event={event}
        dismissOnSelect
        onDidDismiss={() => {
          setIsOpen(false);
        }}
        showBackdrop={false}
        keyboardClose={false}
      >
        {unarchivedProjects?.map((project) => {
          return (
            <IonItem
              button
              key={project.id}
              onClick={(e) => {
                selectJob(project);
              }}
            >
              <ProjectLabel project={project} />
            </IonItem>
          );
        })}
      </IonPopover>
    </IonItem>
  );
};
