import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonToggle,
} from "@ionic/react";
import {
  Header,
  ProjectItem,
  ProjectCard,
  SearchCard,
  LayoutButton,
  SortItem,
  TimesheetModal,
  CreateButton,
  ProjectModal,
  ProjectListHeader,
} from "../components";
import { useProjects, useTheme, useTimesheets, useUser } from "../hooks";
import { DisplayLayout } from "../context";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

// Config options
export const Projects: React.FC = () => {
  // Layout style
  const { layout } = useTheme();

  const { security } = useUser();
  const location = useLocation();

  // New proejct tag
  const [newProject, setNewProject] = useState(false);

  // Link Project hook
  const {
    activeProjectId,
    queriedProjects,
    setProjectKeyword,
    ProjectSortProperties,
    projectSort,
    handleSortChange,
    setActiveProjectId,
    createProject,
    setRefreshProjects,
    deleteProject,
    setProjectStatus,
    projectStatus,
  } = useProjects();

  const handleCreate = () => {
    setNewProject(true);
    createProject();
  };

  // Link tiesheet hook
  const { activeTimesheetId, setActiveTimesheetID, createTimesheet } =
    useTimesheets();

  return (
    <IonPage>
      {/* Header */}
      <Header title="Jobs" />

      {/* Page Content */}
      <IonContent fullscreen>
        {/* Seach Card */}
        <SearchCard onKeywordChange={setProjectKeyword} />

        {/* Grid layout for page */}
        <IonGrid>
          <IonRow>
            {/* Sort options */}
            <IonCol size="3">
              <SortItem
                sortProperty={projectSort}
                sortProperties={ProjectSortProperties}
                onSortChange={handleSortChange}
              />
            </IonCol>

            {/* Archived */}
            
            <IonCol size="2">
              {(security.isAdmin || security.isOffice) &&
                <IonItem>
                  <IonLabel>Archived</IonLabel>
                  <IonToggle
                    checked={projectStatus === "ARCHIVED"}
                    onIonChange={() => {
                      projectStatus === "OPEN"
                        ? setProjectStatus("ARCHIVED")
                        : setProjectStatus("OPEN");
                    }}
                  />
                </IonItem>
              }
            </IonCol>

            {/* Display style */}
            <IonCol size="4" offset="3">
              <IonButtons style={{ justifyContent: "flex-end" }}>
                {(security.isAdmin || security.isOffice) &&
                  <CreateButton onCreate={() => handleCreate()} />
                }
                <LayoutButton />
              </IonButtons>
            </IonCol>
          </IonRow>

          {/* Display table */}
          {layout === DisplayLayout.TABLE && (
            <>
              <IonRow>
                <IonCol>
                  <ProjectListHeader />
                </IonCol>
              </IonRow>

              {queriedProjects?.map((project, index) => {
                return (
                  <IonRow key={index}>
                    <IonCol key={index}>
                      <ProjectItem
                        key={project.id}
                        project={project}
                        onView={() => setActiveProjectId(project.id)}
                        addTimesheet={() => createTimesheet(project.id)}
                      />
                    </IonCol>
                  </IonRow>
                );
              })}
            </>
          )}

          {/* Cards */}
          {layout === DisplayLayout.GRID && (
            <IonRow>
              {queriedProjects?.map((project, index) => {
                return (
                  <IonCol key={index} size="12" sizeMd="4">
                    <ProjectCard
                      key={project.id}
                      project={project}
                      onView={() => setActiveProjectId(project.id)}
                      addTimesheet={() => createTimesheet(project.id)}
                    />
                  </IonCol>
                );
              })}
            </IonRow>
          )}
        </IonGrid>

        {/* Project information */}
        <ProjectModal
          projectId={activeProjectId}
          newProject={newProject}
          onDelete={() => {
            deleteProject();
            setActiveProjectId(undefined);
            newProject ? setNewProject(false) : setRefreshProjects(true);
          }}
          onDismiss={() => {
            setActiveProjectId(undefined);
            setRefreshProjects(true);
            setNewProject(false);
          }}
          onSave={(projectId) => {
            window.location.href = location.pathname + "/" + projectId;
          }}
        />

        {/* Hours */}
        <TimesheetModal
          timesheetId={activeTimesheetId}
          onDismiss={() => setActiveTimesheetID(undefined)}
        />
      </IonContent>
    </IonPage>
  );
};
