import React from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonListHeader,
  IonToolbar,
} from "@ionic/react";
import { LabourCosts, ProjectRevenue } from "../../hooks";
import { FinancialLineItem } from "..";

interface FinancialStatementCardProps {
  labour: LabourCosts | undefined;
  revenue: ProjectRevenue | undefined;
  showUnapproved?: boolean;
}

export const FinancialStatementCard: React.FC<FinancialStatementCardProps> = (
  props
) => {
  return (
    <IonCard style={{ height: "100%" }}>
      <IonCardHeader>
        <IonToolbar>
          <IonCardTitle>Financial Statement</IonCardTitle>
        </IonToolbar>
      </IonCardHeader>
      <IonCardContent>
        {/* Income */}
        <IonList lines="none">
          <IonListHeader className="ion-no-padding">Income</IonListHeader>
          <FinancialLineItem
            label="Invoices"
            color="success"
            value={props.revenue?.income || 0}
          />
        </IonList>
        {/* Labour */}
        <IonList lines="none">
          <IonListHeader className="ion-no-padding">Labour</IonListHeader>
          <FinancialLineItem
            label="Plumbing"
            color="plumbing"
            value={props.labour?.plumbing || 0}
          />
          <FinancialLineItem
            label="HVAC"
            color="hvac"
            value={props.labour?.hvac || 0}
          />
          {props.showUnapproved && 
            <FinancialLineItem
              label="Unapproved"
              color="unapproved"
              value={props.labour?.unapproved || 0}
            />
          }
        </IonList>

        {/* Expenses */}
        <IonList lines="none">
          <IonListHeader className="ion-no-padding">Expenses</IonListHeader>
          <FinancialLineItem
            label="Vendor"
            color="dark"
            value={props.revenue?.vendor || 0}
          />
          <FinancialLineItem
            label="Subcontractor"
            color="medium"
            value={props.revenue?.subcontractor || 0}
          />
          <FinancialLineItem
            label="Misc."
            color="light"
            value={props.revenue?.misc || 0}
          />
        </IonList>

        <IonList lines="none">
          <IonListHeader className="ion-no-padding">Total</IonListHeader>
          <FinancialLineItem
            label="Revenue Total"
            color="medium"
            value={props.revenue !== undefined ? (
              props.revenue?.income
              - props.revenue?.misc +
              - props.revenue?.subcontractor +
              - props.revenue?.vendor
              + ((props.labour?.hvac || 0) + (props.labour?.plumbing || 0)) + (props.showUnapproved ? (props.labour?.unapproved || 0 ) : 0)
            ) : 0}
          />
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};
