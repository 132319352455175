import { IonList, IonItem, IonIcon, IonButton, IonCol } from "@ionic/react";
import { addCircleOutline } from "ionicons/icons";
import { JobInput, NumberInput } from "..";
import { useProjects } from "../../hooks";
import { useEffect, useState } from "react";
import { NumberStringInput } from "./NumberStringInput";

interface ProjectAllocationInputProps {
  canEdit: boolean;
  projectIds: (string | null)[] | null | undefined;
  projectAllocations: (string | null)[] | null | undefined;
  onAddAllocation: () => void;
  onSelectProject: (index: number, id: string) => void;
  updateAllocation: (index: number, amount: string) => void;
  onRemove: (index: number) => void;
  setErrorCount?: (value: number) => void;
  onSetMismatchCounter?: (value: any) => void;
}

export const ProjectAllocationInput: React.FC<ProjectAllocationInputProps> = (
  props
) => {

  const { getProjectById } = useProjects();
  const [ mismatchcounter, setMismatchCounter ] = useState<any>([]);

  let doSetMismatchCounter = function(val: any) {
    setMismatchCounter(val)
    console.log('sera?????');
    if (props.onSetMismatchCounter) {
      console.log('sera?');
      props.onSetMismatchCounter(val);
    }
  }

  return (
    // Show new
    <>
      {/* Project inputs */}
      <IonCol>
        <JobInput
          id={props.projectIds![0]!}
          onSelect={(id) => {
            props.onSelectProject(0, id);
            let amismatchcount = mismatchcounter;
            amismatchcount[0] = 0;
            doSetMismatchCounter(amismatchcount);
          }}
          onType={(value) => {
            let amismatchcount = mismatchcounter;
            if (props.projectIds && props.projectIds.length && props.projectIds![0]) {
              let theprojectid = props.projectIds![0];
              if (!(theprojectid in amismatchcount)) {
                amismatchcount[0] = 0;
              }
              let mismatchcount = 0;
              console.log(value);
              console.log(theprojectid || 'NONE');
              if (theprojectid) {
                getProjectById(theprojectid).then((iproject) => {
                  let iKeyword =
                    "" +
                    iproject?.code?.toUpperCase() +
                    iproject?.number?.toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    });
                  console.log("iKeyword");
                  console.log(iKeyword);
                  console.log("value");
                  console.log(value);
                  if (value !== iKeyword) {
                    mismatchcount++;
                  }                      
                  console.log('mismatchcount');      
                  console.log(mismatchcount);
                  amismatchcount[0] = mismatchcount;
                  doSetMismatchCounter(amismatchcount);
                  console.log(amismatchcount);
                });
              } else {
                mismatchcount++;
                amismatchcount[0] = mismatchcount;
                doSetMismatchCounter(amismatchcount);
                console.log(amismatchcount);
              }
            } else {
              amismatchcount[0] = 1;
              doSetMismatchCounter(amismatchcount);
              console.log(amismatchcount);
            }
          }}
          onBlur={(value) => {
            let amismatchcount = mismatchcounter;
            if (props.projectIds && props.projectIds.length && props.projectIds![0]) {
              let theprojectid = props.projectIds![0];
              if (!(theprojectid in amismatchcount)) {
                amismatchcount[0] = 0;
              }
              let mismatchcount = 0;
              console.log(value);
              console.log(theprojectid || 'NONE');
              if (theprojectid) {
                getProjectById(theprojectid).then((iproject) => {
                  let iKeyword =
                    "" +
                    iproject?.code?.toUpperCase() +
                    iproject?.number?.toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    });
                  console.log("iKeyword");
                  console.log(iKeyword);
                  console.log("value");
                  console.log(value);
                  if (value !== iKeyword) {
                    mismatchcount++;
                  }                      
                  console.log('mismatchcount');      
                  console.log(mismatchcount);
                  amismatchcount[0] = mismatchcount;
                  doSetMismatchCounter(amismatchcount);
                  console.log(amismatchcount);
                });
              } else {
                mismatchcount++;
                amismatchcount[0] = mismatchcount;
                doSetMismatchCounter(amismatchcount);
                console.log(amismatchcount);
              }
            } else {
              amismatchcount[0] = 1;
              doSetMismatchCounter(amismatchcount);
              console.log(amismatchcount);
            }
          }}
        />
        {/* If there are alocatino, display them */}
        {props.projectIds?.map((projectId, index) => {
          if (index > 0) {
            return (
              <JobInput
                key={"inputProject" + projectId}
                hideLable={true}
                id={props.projectIds![index]!}
                onSelect={(id) => {
                  console.log(index, projectId, id);
                  props.onSelectProject(index, id);
                  let amismatchcount = mismatchcounter;
                  amismatchcount[index] = 0;
                  doSetMismatchCounter(amismatchcount);
                }}
                onType={(value) => {
                  let amismatchcount = mismatchcounter;
                  if (props.projectIds && props.projectIds.length && props.projectIds![index]) {
                    let theprojectid = props.projectIds![index] || '';
                    if (!(theprojectid in amismatchcount)) {
                      amismatchcount[index] = 0;
                    }
                    let mismatchcount = 0;
                    console.log(value);
                    console.log(theprojectid || 'NONE');
                    if (theprojectid) {
                      getProjectById(theprojectid).then((iproject) => {
                        let iKeyword =
                          "" +
                          iproject?.code?.toUpperCase() +
                          iproject?.number?.toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          });
                        console.log("iKeyword");
                        console.log(iKeyword);
                        console.log("value");
                        console.log(value);
                        if (value !== iKeyword) {
                          mismatchcount++;
                        }                      
                        console.log('mismatchcount');      
                        console.log(mismatchcount);
                        amismatchcount[index] = mismatchcount;
                        doSetMismatchCounter(amismatchcount);
                        console.log('amismatchcount');
                        console.log(amismatchcount);  
                      });
                    } else {
                      mismatchcount++;
                      amismatchcount[index] = mismatchcount;
                      doSetMismatchCounter(amismatchcount);
                      console.log('amismatchcount');
                      console.log(amismatchcount);
                    }
                  } else {
                    amismatchcount[index] = 1;
                    doSetMismatchCounter(amismatchcount);
                    console.log('amismatchcount');
                    console.log(amismatchcount);
                  }
                }}
                onBlur={(value) => {
                  let amismatchcount = mismatchcounter;
                  if (props.projectIds && props.projectIds.length && props.projectIds![index]) {
                    let theprojectid = props.projectIds![index] || '';
                    if (!(theprojectid in amismatchcount)) {
                      amismatchcount[index] = 0;
                    }
                    let mismatchcount = 0;
                    console.log(value);
                    console.log(theprojectid || 'NONE');
                    if (theprojectid) {
                      getProjectById(theprojectid).then((iproject) => {
                        let iKeyword =
                          "" +
                          iproject?.code?.toUpperCase() +
                          iproject?.number?.toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          });
                        console.log("iKeyword");
                        console.log(iKeyword);
                        console.log("value");
                        console.log(value);
                        if (value !== iKeyword) {
                          mismatchcount++;
                        }                      
                        console.log('mismatchcount');      
                        console.log(mismatchcount);
                        amismatchcount[index] = mismatchcount;
                        doSetMismatchCounter(amismatchcount);
                        console.log('amismatchcount');
                        console.log(amismatchcount);  
                      });
                    } else {
                      mismatchcount++;
                      amismatchcount[index] = mismatchcount;
                      doSetMismatchCounter(amismatchcount);
                      console.log('amismatchcount');
                      console.log(amismatchcount);
                    }
                  } else {
                    amismatchcount[index] = 1;
                    doSetMismatchCounter(amismatchcount);
                    console.log('amismatchcount');
                    console.log(amismatchcount);
                  }
                }}
              />
            );
          }
        })}
      </IonCol>

      {/* Ammunt inputs */}
      <IonCol>
        <NumberStringInput
          label={"Allocation ($)"}
          value={props.projectAllocations![0]}
          onChange={(value) => {
            props.updateAllocation(0, value);
          }}
        />
        {/* If there are alocatino, display them */}
        {props.projectIds?.map((projectId, index) => {
          if (index > 0) {
            return (
              <NumberStringInput
                key={"input number" + projectId}
                label={undefined}
                value={props.projectAllocations![index]}
                onChange={(value) => {
                  props.updateAllocation(index, value);
                }}
              />
            );
          }
        })}
      </IonCol>

      {/* Add remove buttons */}
      <IonCol>
        <IonList>
          <IonItem>
            <IonButton onClick={() => props.onAddAllocation()}>
              <IonIcon slot="icon-only" icon={addCircleOutline} />
            </IonButton>
          </IonItem>
        </IonList>
      </IonCol>
    </>
  );
};
