import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
  IonList,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import { DateInput, OnCallItem, OnCallModal } from "..";
import { useOncalls, useTimeOffs, useUser } from "../../hooks";
import { arrowUp, arrowDown, addCircleOutline } from "ionicons/icons";
import { formatAWSDate } from "../../_utils/datetime";
import { TimeOffModal } from "../Modals/TimeOffModal";
import { TimeOffItem } from "../Items/TimeOffItem";
//import { Picker } from 'react-month-picker';

interface TimeOffRequestListProps {}

export const TimeOffRequestList: React.FC<TimeOffRequestListProps> = () => {

  // User
  const { authUser, security } = useUser();

  // Show all onCallList

  let stoday = new Date();
  let senddate = stoday;
  let sstartdate = new Date();
  sstartdate.setDate(sstartdate.getDate() - 70);

  const [hideTimeOffList, setHideTimeOffList] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState<Date | undefined>(undefined);
  const [filterEndDate, setFilterEndDate] = useState<Date | undefined>(undefined);

  // Connect to hool
  const {
    createTimeOffEvent,
    queriedTimeOff,
    setKeyword,
    setActiveTimeOffId,
    activeTimeOffId,
  } = useTimeOffs();

  //const pickAMonth = React.createRef();

  return (
    <>
      <IonCard>
        <IonToolbar>
          <IonTitle>Timeoff Requests</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => createTimeOffEvent((id: any) => {
              console.log('testando');
              console.log(id);
              setActiveTimeOffId(id);
            })}>
              <IonIcon slot="icon-only" icon={addCircleOutline} />
            </IonButton>
            <IonButton onClick={() => setHideTimeOffList(!hideTimeOffList)}>
              {hideTimeOffList ? (
                <IonIcon icon={arrowDown} />
              ) : (
                <IonIcon icon={arrowUp} />
              )}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonCardContent hidden={hideTimeOffList}>
          <IonRow>
            <IonCol size="5">
              {/* Date */}
              <DateInput
                tid="aaaa"
                label="Start Date"
                value={filterStartDate}
                disabled={false}
                onChange={(value) => {
                    setFilterStartDate(value);
                  }
                }
                
              />
            </IonCol>
            <IonCol size="5">
              {/* Date */}
              <DateInput
                  tid="bhbbb"
                  label="End Date"
                  value={filterEndDate}
                  disabled={false}
                  onChange={(value) => {
                      setFilterEndDate(value);
                    }
                  }
                />
            </IonCol>
          </IonRow>
          <IonSearchbar
            onIonChange={(e) => setKeyword(e.detail.value as string)}
          />
          
          <IonList lines="none">
            {queriedTimeOff?.filter((o) => {
                return (o.timeOffEmployeeId) && o.details && (security.isAdmin || security.isOffice ||  o.timeOffEmployeeId === authUser!.id) && (!filterStartDate || o.start >= formatAWSDate(filterStartDate)) && (!filterEndDate || o.start <= formatAWSDate(filterEndDate));
              })
              .map((timeOff) => {
              return (
                <TimeOffItem
                  key={timeOff.id}
                  timeOff={timeOff}
                  onEdit={() => setActiveTimeOffId(timeOff.id)}
                />
              );
            })}
          </IonList>
        </IonCardContent>
      </IonCard>

      {/* On Call Modal */}
      <TimeOffModal
        timeOffId={activeTimeOffId}
        onDismiss={() => setActiveTimeOffId(undefined)}
      />
    </>
  );
};
