import { IonCol, IonGrid, IonItem, IonRow } from "@ionic/react";
import { Invoice } from "../../models";
import { DeleteButton, EditButton } from "../Buttons";
import { useInvoices } from "../../hooks";
import React, { useState } from "react";

interface ExpenseItemProps {
  invoice: Invoice;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const ExpenseItem: React.FC<ExpenseItemProps> = (props) => {

  const onDelete = props.onDelete === null || props.onDelete === undefined ? null : props.onDelete;
  const onEdit = props.onEdit === null || props.onEdit === undefined ? null : props.onEdit;

  const { getPOs } = useInvoices();

  let [ pos, setPos ] = useState<any>("-");

  React.useEffect(() => {
    getPOs(props.invoice.projectIds as Array<string>).then((data) => {
      setPos(data);
    });
  }, []);

  const odate = new Date(props.invoice.date + "T00:00:00");
  const formattedDate = odate.toLocaleDateString("en-US", {weekday: 'short', year: 'numeric', month: 'long', day: 'numeric'});

  return (
    <IonItem>
      <IonGrid>
        <IonRow>
          {/* Invoice number */}
          <IonCol size="12" sizeSm="2">
            {props.invoice.business}
          </IonCol>
          {/* Number */}
          <IonCol size="12" sizeSm="2">
            {props.invoice.number}
          </IonCol>
          {/* Amount */}
          <IonCol size="12" sizeSm="1" className="ion-text-left">
            ${props.invoice.amount}
          </IonCol>
          <IonCol size="12" sizeSm="2">
            {formattedDate}
          </IonCol>
          {/* Description */}
          <IonCol size="12" sizeSm="2">
            {props.invoice.description}
          </IonCol>
          {/* Job PO */}
          <IonCol size="12" sizeSm="2">
            {pos[0]}
          </IonCol>
          <IonCol className="ion-text-right" sizeSm="1" size="12">
            {onEdit &&
              <EditButton onEdit={() => onEdit()} />
            }
            {onDelete && 
              <DeleteButton onDelete={() => onDelete()} />
            }
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};
