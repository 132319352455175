import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonPopover,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline, pencilOutline, personOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useEmployees, useOnCall } from "../../hooks";
import { formatIonDateTimeString } from "../../_utils/datetime";

interface OnCallModalInterface {
  onCallId: null | string | undefined;
  onDismiss: () => void;
}

export const OnCallModal: React.FC<OnCallModalInterface> = (props) => {
  // State
  const [showModal, setShowModal] = useState(false);

  // Hook together that shit
  const {
    activeOnCallForm,
    setActiveOnCallId,
    setActiveOnCallForm,
    saveOnCall,
    deleteOnCall,
    deleteOnCallIfNullEmployee
  } = useOnCall();

  const { fieldEmployees } = useEmployees();

  // Handle save
  const handleSave = async () => {
    await saveOnCall();
    setShowModal(false);
    props.onDismiss();
  };

  // Handle deletion
  const handleDelete = async () => {
    await deleteOnCall();
    setShowModal(false);
    props.onDismiss();
  };

  const handleDeleteIfNullEmployee = async () => {
    await deleteOnCallIfNullEmployee();
    setShowModal(false);
    props.onDismiss();
  };

  // Auto open
  useEffect(() => {
    if (props.onCallId) {
      setActiveOnCallId(props.onCallId);
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [props.onCallId]);

  const handleDismiss = async () => {
    await handleDeleteIfNullEmployee();
    props.onDismiss();
  };

  // Modal
  return (
    <IonModal isOpen={showModal} onDidDismiss={() => handleDismiss()}>
      {/* Toolbar */}
      <IonToolbar>
        {/* Modal title */}
        <IonTitle>
          On Call: {activeOnCallForm?.employee?.username?.toLocaleUpperCase()}
        </IonTitle>

        {/* Close modal */}
        <IonButtons slot="end">
          <IonButton onClick={() => handleDismiss()}>
            <IonIcon slot="icon-only" icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>

      {/* Oncall details */}
      <IonContent>
        {activeOnCallForm && (
          <IonList lines="none">
            {/* Header section */}
            <IonListHeader>
              <IonText color="medium">Details</IonText>
            </IonListHeader>

            {/* Assignement */}
            <IonItem id="open-employee-edit" button>
              <IonLabel>Employee</IonLabel>
              <IonText>
                {activeOnCallForm.employee?.username?.toLocaleUpperCase()}
              </IonText>

              {/* List out employees*/}
              <IonPopover
                trigger="open-employee-edit"
                showBackdrop={false}
                dismiss-on-select="true"
              >
                {fieldEmployees
                  ?.filter(
                    (employee) =>
                      !activeOnCallForm?.employee?.id.includes(employee.id)
                  )
                  .map((employee) => {
                    return (
                      <IonItem
                        key={employee.id}
                        button
                        onClick={() => {
                            console.log(employee);
                            setActiveOnCallForm({
                              ...activeOnCallForm!,
                              employee: employee,
                            })
                          }
                        }
                      >
                        <IonLabel>
                          {employee.username?.toLocaleUpperCase()}
                        </IonLabel>
                      </IonItem>
                    );
                  })}
              </IonPopover>
            </IonItem>

            {/* Start input */}
            <IonItem id="open-start-input" button>
              <IonLabel>From</IonLabel>
              <IonText slot="end">
                {activeOnCallForm?.start!.toDateString()}
              </IonText>
              <IonPopover
                trigger="open-start-input"
                dismiss-on-select={false}
                showBackdrop={false}
              >
                <IonDatetime
                  presentation="date"
                  value={formatIonDateTimeString(activeOnCallForm?.start!)}
                  onIonChange={(e) => {
                    setActiveOnCallForm({
                      ...activeOnCallForm!,
                      start: new Date(e.detail.value!),
                    });
                  }}
                ></IonDatetime>
              </IonPopover>
            </IonItem>

            {/* To */}
            <IonItem id="open-end-input" button>
              <IonLabel>To</IonLabel>
              <IonText slot="end">
                {activeOnCallForm?.end.toDateString()}
              </IonText>
              <IonPopover
                trigger="open-end-input"
                dismiss-on-select={false}
                showBackdrop={false}
              >
                <IonDatetime
                  presentation="date"
                  value={formatIonDateTimeString(activeOnCallForm?.end!)}
                  onIonChange={(e) => {
                    setActiveOnCallForm({
                      ...activeOnCallForm!,
                      end: new Date(e.detail.value!),
                    });
                  }}
                ></IonDatetime>
              </IonPopover>
            </IonItem>

            {/* Details */}
            <IonItem lines="none">
              <IonLabel position="stacked" color="medium">
                Notes
              </IonLabel>
              <IonTextarea
                placeholder="Details"
                rows={8}
                value={activeOnCallForm!.note}
                onIonChange={(e) => {
                  setActiveOnCallForm({
                    ...activeOnCallForm!,
                    note: e.detail.value as string,
                  });
                }}
              ></IonTextarea>
            </IonItem>
          </IonList>
        )}

        {/* Handle save */}
        <IonButton expand="block" onClick={() => handleSave()}>
          Save
        </IonButton>
        <IonButton expand="block" color="danger" onClick={() => handleDelete()}>
          Delete
        </IonButton>
      </IonContent>
    </IonModal>
  );
};
