import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
  IonText,
} from "@ionic/react";
import { eyeOutline } from "ionicons/icons";
import { TimeOff } from "../../models";
import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";

interface TimeOffItemInterface {
  timeOff: TimeOff;
  onEdit: () => void;
}

export const TimeOffItem: React.FC<TimeOffItemInterface> = (props) => {
  console.log(props.timeOff);

  const [ employeeName, setEmployeeName ] = useState<string>("");

  useEffect(() => {
    DataStore.query(TimeOff, props.timeOff.id).then((o) => {
      setEmployeeName(o?.employee!.username ? o?.employee!.username.toUpperCase() : "");
    });
  }, []);

  return (
    <IonItem key={props.timeOff.id}>
      <IonGrid>
        <IonRow>
          <IonCol>
            {employeeName}
          </IonCol>
          <IonCol>
            {new Date(props.timeOff.start + "T08:00").toDateString() +
              " to " +
              new Date(props.timeOff.end + "T08:00").toDateString()}
          </IonCol>
          <IonCol className="ion-text-right">
            <IonButton onClick={() => props.onEdit()}>
              <IonIcon slot="icon-only" icon={eyeOutline} />
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};
