import { IonInput, IonItem, IonLabel, IonText, useIonViewDidEnter } from "@ionic/react";
import { useRef } from "react";

interface StringInputProps {
  label: null | string | undefined;
  value: string | number | undefined | null;
  disabled?: boolean | undefined;
  onChange: (value: string) => void;
  error?: { error: boolean | undefined; msg: string };
}

export const NumberStringInput: React.FC<StringInputProps> = (props) => {  
  return (
    <IonItem>
      {props.label && <IonLabel position="stacked">{props.label}</IonLabel>}
      <IonInput
        type="text"
        value={props.value}
        disabled={props.disabled}
        onKeyUp={(e) => {
          props.onChange(props.value as string);
          // if (e.key === '-') {
          //   e.preventDefault();
          //   e.stopPropagation();
          //   let absval = Math.abs(typeof props.value! === 'string' ? parseFloat(props.value!) : props.value!);
          //   console.log({"key": e.key, "propsvalue": props.value, absval});
          //   if (absval > 0) {
          //     props.onChange(Number(-absval));
          //   } else {
          //     //props.onChange(-1);
          //     //e.target.value = "-0";
          //     e.currentTarget.value = '-';
          //   }            
          // }
        }}
        onIonChange={(e) => {
          // if (!isNaN(Number(e.detail.value))) {
          //   props.onChange(Number(e.detail.value));
          // }
          props.onChange(e.detail.value || '');
        }}
      />
      {props.error?.error && (
        <IonText color="danger">{props.error.msg}</IonText>
      )}
    </IonItem>
  );
};
