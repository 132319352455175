import { useEffect, useState } from "react";
import {
  IonList,
  IonListHeader,
  IonText,
  IonItem,
  IonIcon,
  IonPopover,
  IonRadioGroup,
  IonLabel,
  IonRadio,
} from "@ionic/react";
import { createOutline } from "ionicons/icons";
import { PhoneButton } from "../";
import { useContacts } from "../../hooks";
import { Contact, ContactType } from "../../models";

interface SupervisorInputInterface {
  parentId: null | string | undefined;
  id: null | string | undefined;
  canEdit: boolean;
  onSelect: (id: string) => void;
  displayNone?: boolean;
}

export const SupervisorInput: React.FC<SupervisorInputInterface> = (props) => {
  // GEt contactinos
  const { queriedContacts } = useContacts();

  // Subset
  const [supervisors, setSupervisors] = useState<Contact[] | undefined>();

  // Update subset
  useEffect(() => {
    if (props.parentId) {
      let supervisorContact = queriedContacts?.filter((contact) => {
        return contact.builderId === props.parentId ? true : false;
      });
      setSupervisors(supervisorContact);
    } else {
      let supervisorContact = queriedContacts?.filter((contact) => {
        return contact.type === ContactType.SUPERVISOR;
      });
      setSupervisors(supervisorContact);
    }
  }, [props.parentId, queriedContacts]);

  return (
    <IonList id="supervisor-input-list">
      <IonListHeader>
        <IonText color="medium">Job Supervisor</IonText>

        <IonItem id="open-contact-input" button disabled={!props.canEdit}>
          {props.canEdit && <IonIcon color="primary" icon={createOutline} />}
          <IonPopover
            trigger="open-contact-input"
            showBackdrop={false}
            dismiss-on-select="true"
          >
            <IonRadioGroup
              onIonChange={(e) => props.onSelect(e.detail.value)}
              value={props.id}
            >
              {props.displayNone &&
                <IonItem key={null}>
                  <IonLabel> NONE</IonLabel>
                  <IonRadio slot="start" value={null} />
                </IonItem>
              }
              {supervisors?.map((contact) => {
                return (
                  <IonItem key={contact.id}>
                    <IonLabel> {contact.name}</IonLabel>
                    <IonRadio slot="start" value={contact.id} />
                  </IonItem>
                );
              })}
            </IonRadioGroup>
          </IonPopover>
        </IonItem>
      </IonListHeader>

      {props.id &&
        supervisors?.map((contact, index) => {
          if (contact.id === props.id) {
            return (
              <IonItem key={index}>
                <IonLabel>{contact.name}</IonLabel>
                <IonText slot="end">
                  <PhoneButton tel={contact.phone} />
                </IonText>
              </IonItem>
            );
          }
        })}
    </IonList>
  );
};
