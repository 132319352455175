import {
  IonButton,
  IonButtons,
  IonContent,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useDocument } from "../../hooks";
import { CloseButton } from "../Buttons";
import {
  DateInput,
  DocumentInput,
  ProjectInput,
  StringInput,
  ToggleInput,
} from "../";
import { formatAWSDate } from "../../_utils/datetime";

interface DocModalProps {
  docId: null | string | undefined;
  onDismiss?: () => void;
  //updateRanks?: () => void;
}

export const DocumentModal: React.FC<DocModalProps> = (props) => {
  // User
  // Stae
  const [isOpen, setIsOpen] = useState(false);

  // Hook to data
  const { setActiveDocId, activeDocForm, setActiveDocForm, saveDocument } =
    useDocument();

  // Save shti
  const handleSubmit = async () => {
    console.log('t1');
    await saveDocument();
    console.log('t2');
    setActiveDocForm(undefined);
    console.log('props.onDismiss');
    console.log(props.onDismiss);
    if (props.onDismiss) {
      props.onDismiss();
    }
    // if (props.updateRanks) {
    //   props.updateRanks();
    // }
  };
  // Trigger
  useEffect(() => {
    setActiveDocId(props.docId !== null ? props.docId : undefined);
    props.docId ? setIsOpen(true) : setIsOpen(false);
  });

  console.log('>>> ' + formatAWSDate(new Date()));
  console.log(activeDocForm);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => { if (props.onDismiss) { props.onDismiss() }}} >
      {/* Toolbar */}
      <IonToolbar>
        <IonButtons slot="end">
          <CloseButton onClick={() => {if (props.onDismiss) { props.onDismiss() }}} />
        </IonButtons>
      </IonToolbar>

      {/* Form data */}
      {activeDocForm && (
        <IonContent>
          {/* Document Input */}
          <DocumentInput
            label="Document"
            value={activeDocForm.key !== null ? activeDocForm.key : undefined}
            onUpload={(key) => setActiveDocForm({ ...activeDocForm, key: key })}
          />

          {/* Name */}
          <StringInput
            label="Name"
            value={activeDocForm.name}
            canEdit={true}
            onChange={(value) =>
              setActiveDocForm({ ...activeDocForm, name: value })
            }
          />

          {/* Description */}
          <StringInput
            label="Description"
            value={activeDocForm.description}
            canEdit={true}
            onChange={(value) =>
              setActiveDocForm({ ...activeDocForm, description: value })
            }
          />

          {/* Date */}
          <DateInput
            value={activeDocForm.date!}
            disabled={false}
            onChange={(value) =>
              setActiveDocForm({ ...activeDocForm, date: value })
            }
          />

          {/* Approval */}
          <ToggleInput
            label="Approved"
            value={activeDocForm.approved!}
            disabled={false}
            onChange={(value) =>
              setActiveDocForm({ ...activeDocForm, approved: value })
            }
          />

          {/* Projects */}
          <ProjectInput
            id={activeDocForm.projectId}
            canEdit={true}
            onSelect={(id) =>
              setActiveDocForm({ ...activeDocForm, projectId: id })
            }
          />

          {/* Save */}
          <IonButton
            expand="full"
            disabled={!activeDocForm.key}
            onClick={() => handleSubmit()}
          >
            Save
          </IonButton>
        </IonContent>
      )}
    </IonModal>
  );
};
