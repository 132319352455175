import { IonCol, IonGrid, IonItem, IonRow, IonText } from "@ionic/react";

interface ExpensesListHeaderProps {
  category?: string;
}
export const ExpensesListHeader: React.FC<ExpensesListHeaderProps> = (
  props
) => {
  let scategory = props.category && props.category.length > 0 ? props.category.substring(0, 1).toUpperCase() + props.category.substring(1).toLocaleLowerCase() : '';
  return (
    <IonItem>
      <IonGrid>
        <IonRow>
          <IonCol size="12" sizeSm="2">
            <IonText color="medium">{scategory}</IonText>
          </IonCol>
          <IonCol size="12" sizeSm="2">
            <IonText color="medium">Invoice #</IonText>
          </IonCol>
          <IonCol size="12" sizeSm="1">
            <IonText color="medium">Amount</IonText>
          </IonCol>
          <IonCol size="12" sizeSm="2">
            <IonText color="medium">Date</IonText>
          </IonCol>
          <IonCol size="12" sizeSm="2">
            <IonText color="medium">Description</IonText>
          </IonCol>
          <IonCol size="12" sizeSm="2">
            <IonText color="medium">Job PO</IonText>
          </IonCol>
          <IonCol className="ion-text-right" size="12" sizeSm="1">
            <IonText color="medium">Edit</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};
