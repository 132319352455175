import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { Contact, ContactType } from "../models";

interface FormErrors {
  email?: boolean;
  code?: boolean;
  phone?: boolean;
  supervisor?: boolean;
}
interface ContactForm {
  code: null | string | undefined;
  email: null | string | undefined;
  name: null | string | undefined;
  phone: null | string | undefined;
  type: ContactType;
  builderId: null | string | undefined;
  addressId: null | string | undefined;
}

export function useContact() {
  // State
  const [activeId, setActiveId] = useState<string>();
  const [activeContact, setActiveContact] = useState<ContactForm>();
  const [errors, setErrors] = useState<FormErrors>();

  // Load contact
  const loadContact = async () => {
    try {
      // Get data from db
      const data = await DataStore.query(Contact, activeId!);

      // Set contact form state
      if (data)
        setActiveContact({
          code: data.code,
          email: data.email,
          name: data.name,
          phone: data.phone,
          type: data.type as ContactType,
          builderId: data.builderId,
          addressId: data.contactAddressId || "new",
        });

      // Load data into state
      setErrors(undefined);
    } catch (error) {
      console.log("Error loading contact.");
    }
  };

  // Create contact
  const createContact = async () => {
    // Set state
    setActiveContact({
      code: "",
      email: "",
      name: "New Contact",
      phone: "",
      type: "BUILDER" as ContactType,
      builderId: undefined,
      addressId: "new",
    });

    // Clear state of
    setErrors(undefined);
  };

  // Check email
  const emailCheck = async () => {
    if (activeContact?.email) {
      // Check if email already exists
      const data = await DataStore.query(Contact, (c) =>
        c.id("ne", activeId!).email("eq", activeContact?.email!)
      );

      data.length > 0
        ? setErrors({ ...errors, email: true })
        : setErrors({ ...errors, email: false });
    } else {
      setErrors({ ...errors, email: false });
    }
  };

  // Check phione
  const phoneCheck = async () => {
    if (activeContact?.phone) {
      // Check if email already exists
      const data = await DataStore.query(Contact, (c) =>
        c.id("ne", activeId!).phone("eq", activeContact?.phone!)
      );

      data.length > 0
        ? setErrors({ ...errors, phone: true })
        : setErrors({ ...errors, phone: false });
    } else {
      setErrors({ ...errors, phone: false });
    }
  };

  // Check code
  const codeCheck = async () => {
    if (activeContact?.code) {
      // Check if code already exists
      
      let data = null;

      if (['MI', 'SC'].includes(activeContact?.code!)) {
        data = [];
      } else {
        data = await DataStore.query(Contact, (c) =>
          c.id("ne", activeId!).code("eq", activeContact?.code!).deleted("ne", true)
        );
      }      

      data.length > 0 ? setErrors({ code: true }) : setErrors({ code: false });
    } else {
      setErrors({ ...errors, code: false });
    }
  };

  // Supervision chekc
  const typeCheck = async () => {
    if (activeContact?.type === ContactType.SUPERVISOR) {
      activeContact.builderId === null || activeContact.builderId === undefined
        ? setErrors({ ...errors, supervisor: true })
        : setErrors({ ...errors, supervisor: false });
    } else {
      setErrors({ ...errors, supervisor: false });
    }
  };

  // Save contact
  const saveContact = async () => {
    try {
      let original = await DataStore.query(Contact, activeId!);

      if (activeContact?.type === ContactType.BUILDER) {
        await DataStore.save(
          Contact.copyOf(original!, (updated) => {
            updated.type = ContactType.BUILDER;
            updated.name = activeContact?.name;
            updated.email = activeContact?.email;
            updated.phone = activeContact?.phone;
            updated.code = activeContact.code;
            updated.address = undefined;
            updated.contactAddressId = activeContact.addressId;
          })
        );
      } else if (activeContact?.type === ContactType.SUPERVISOR) {
        await DataStore.save(
          Contact.copyOf(original!, (updated) => {
            updated.type = ContactType.SUPERVISOR;
            updated.name = activeContact?.name;
            updated.email = activeContact?.email;
            updated.phone = activeContact?.phone;
            updated.builderId = activeContact.builderId;
            updated.address = undefined;
            updated.contactAddressId = undefined;
          })
        );
      }
    } catch (error) {
      console.log("Error saving contact: ", error);
    }
  };

  // Trigger email cheackl
  useEffect(() => {
    emailCheck();
  }, [activeContact?.email]);

  useEffect(() => {
    phoneCheck();
  }, [activeContact?.phone]);

  // Trigger email cheackl
  useEffect(() => {
    codeCheck();
  }, [activeContact?.code]);

  // Trigger for supervison
  useEffect(() => {
    typeCheck();
  }, [activeContact?.type, activeContact?.builderId]);

  // Triggers
  useEffect(() => {
    if (activeId) {
      activeId === "new" ? createContact() : loadContact();
    } else {
      setActiveContact(undefined);
    }
  }, [activeId]);

  return {
    activeId,
    activeContact,
    setActiveId,
    setActiveContact,
    errors,
    saveContact,
  };
}
