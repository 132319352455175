import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import {
  DateInput,
  DocumentInput,
  StringInput,
  NumberInput,
  SelectInput,
  TextAreaInput,
  ProjectSplitInput,
  ProjectAllocationInput,
} from "../Inputs";
import { useInvoice, useInvoices, useProjects } from "../../hooks";
import { Accounts, Expenses, Project } from "../../models";
import { useInvoiceString } from "../../hooks/useInvoiceString";
import { NumberStringInput } from "../Inputs/NumberStringInput";

interface ExpenseCreateCardProps {
  category: Expenses;
  onCreate: () => void;
  project?: Project;
}

// Header car to create a new expense invoice
export const ExpenseCreateCard: React.FC<ExpenseCreateCardProps> = (props) => {
  // Hook into accounting
  const { activeInvoice, setActiveInvoice, removePartition, addInvoice } =
    useInvoiceString();

  const { queryExpenseInvoicesByNumber } = useInvoices();

  const [ allocationProblem, setAllocationProblem ] = useState<boolean>(false);

  const [ projectmismatchcounter, setProjectMismatchCounter ] = useState<any>({});
  const [ projectmismatchflag, setProjectMismatchFlag ] = useState<any>(true);

  const [ detectedDuplicateInvoiceNumber, setDetectedDuplicateInvoiceNumber ] = useState<boolean>(false);

  // Blank invoice
  const blankInvoice = () => {
    setActiveInvoice({
      amount: '0',
      account: Accounts.EXPENSES,
      category: props.category,
      date: new Date(),
      number: "",
      description: "",
      business: "",
      document: undefined,
      projectIds: activeInvoice ? activeInvoice.projectIds : (props.project ? [props.project.id] : ['']),
      partition: ['0'],
    });
  };

  const blankSomeInvoice = () => {
    let business = activeInvoice?.business
    setActiveInvoice({
      amount: '0',
      account: Accounts.EXPENSES,
      category: props.category,
      date: new Date(),
      number: "",
      description: "",
      business: business,
      document: undefined,
      projectIds: activeInvoice ? activeInvoice.projectIds : (props.project ? [props.project.id] : ['']),
      partition: ['0'],
    });
  };

  // Save invoie
  const handleCreate = async () => {
    await addInvoice();
    props.onCreate();
    switch (props.category) {
      case Expenses.VENDOR:
        blankSomeInvoice();
        break;
      case Expenses.SUBCONTRACTOR:
      case Expenses.MISCELLANEOUS:
        blankInvoice();
        break;
    }
  };

  useEffect(() => {
    if (activeInvoice) {
      let hasProblem = false;
      let projectIds = activeInvoice.projectIds;
      let partition = activeInvoice.partition;
      let amount = parseFloat(activeInvoice.amount || '0');
      if (projectIds!.length !== partition!.length) {
        hasProblem = true;
      } else if (projectIds?.length === 0) {
        hasProblem = false;
      } else if (projectIds?.length === 1 && projectIds[0]?.trim() === '') {
        let apartition = parseFloat(partition![0] || '0');
        if (isNaN(apartition)) {
          apartition = 0.0;
        }
        if (apartition !== 0) {
          hasProblem = true;
        } else {
          hasProblem = false;
        }
      } else {
        let sum = 0.0;
        for (let i = 0; i < partition!.length; i++) {
            let apartition = parseFloat(partition![i]!);
            if (isNaN(apartition)) {
              apartition = 0.0;
            }
            sum += apartition;
        }
        if (sum != amount) {
          hasProblem = true;
        }
      }
      setAllocationProblem(hasProblem);

      if (activeInvoice?.number) {
        queryExpenseInvoicesByNumber(activeInvoice?.number).then((result) => {
          console.log(result);
          if (result != null && result.length > 0) {
            setDetectedDuplicateInvoiceNumber(true);
          } else {
            setDetectedDuplicateInvoiceNumber(false);
          }
        });
      } else {
        setDetectedDuplicateInvoiceNumber(false);
      }

    }
  }, [activeInvoice]);

  // UPDATE CATEGORY
  useEffect(() => {
    setActiveInvoice({
      ...activeInvoice!,
      category: props.category as Expenses,
    });
  }, [props.category]);

  // Auto load
  useEffect(() => {
    blankInvoice();
  }, []);

  return (
    <IonCard>
      {activeInvoice && (
        <>
          {/* Header */}
          <IonCardHeader>
            <IonToolbar>
              <IonTitle>Add Invoice</IonTitle>
              {(!activeInvoice || !activeInvoice.number || !activeInvoice.date || !activeInvoice.description || !activeInvoice.amount || !activeInvoice.projectIds || !activeInvoice.projectIds.length || !activeInvoice.projectIds[0]) &&
                <IonTitle color="danger">Please fill an Invoice #, Amount, Date, Description and Job PO</IonTitle>
              }
              {detectedDuplicateInvoiceNumber && 
                <IonTitle color="danger">Duplicate invoice number</IonTitle>
              }
              {/* Conditional save only when the document is htere */}
              <IonButtons slot="end">
                {/* Save */}
                <IonButton
                  expand="full"
                  /* disabled={activeInvoice !== null && activeInvoice !== undefined}  && !activeInvoice.document */
                  disabled={activeInvoice === null || activeInvoice === undefined || activeInvoice.number === null || activeInvoice.number === undefined || activeInvoice.number === '' || allocationProblem || projectmismatchflag}
                  onClick={() => handleCreate()}
                >
                  Save
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonCardHeader>

          {/* Form */}
          <IonCardContent>
            <IonGrid>
              <IonRow>
                {/* Invoice Reference */}
                <IonCol>
                  <StringInput
                    label={props.category}
                    value={activeInvoice.business}
                    canEdit={true}
                    onChange={(value) =>
                      setActiveInvoice({
                        ...activeInvoice,
                        business: value.toUpperCase(),
                      })
                    }
                  />
                </IonCol>

                {/* Number */}
                <IonCol>
                  <StringInput
                    label="Invoice #"
                    value={activeInvoice.number}
                    canEdit={true}
                    onChange={(value) =>
                      setActiveInvoice({ ...activeInvoice, number: value })
                    }
                  />
                </IonCol>

                {/* Amount */}
                <IonCol>
                  <NumberStringInput
                    label="$"
                    value={activeInvoice?.amount}
                    onChange={(value) =>
                      setActiveInvoice({ ...activeInvoice, amount: value })
                    }
                  />
                </IonCol>

                <IonCol>
                  {/* Date */}
                  <DateInput
                    value={activeInvoice.date}
                    disabled={false}
                    onChange={(value) =>
                      setActiveInvoice({ ...activeInvoice, date: value })
                    }
                  />
                </IonCol>

                {/* Description */}
                <IonCol>
                  <TextAreaInput
                    value={activeInvoice.description}
                    readonly={false}
                    onChange={(value) =>
                      setActiveInvoice({ ...activeInvoice, description: value })
                    }
                  />
                </IonCol>

                {/* Job allocation */}
                {!props.project &&
                  
                  <ProjectAllocationInput
                    canEdit={true}
                    projectIds={activeInvoice.projectIds}
                    projectAllocations={activeInvoice.partition}
                    onSetMismatchCounter={(value) => {
                      console.log('12312312322222');
                      let flag = false;
                      console.log(value.length);
                      if (value && value.length > 0) {
                        for (let singlevalue of value) {
                          if (singlevalue > 0) {
                            flag = true;
                            break;
                          }
                        }
                        setProjectMismatchCounter(value);
                        setProjectMismatchFlag(flag);
                        console.log('value1');
                        console.log(value);
                        console.log('flag1');
                        console.log(flag);  
                      } else {
                        console.log('aaaaaaaaaaaaa');
                        flag = true;
                        setProjectMismatchCounter(value);
                        setProjectMismatchFlag(flag);
                        console.log('value2');
                        console.log(value);
                        console.log('flag2');
                        console.log(flag);  
                      }
                    }}
                    onAddAllocation={() => {
                      // New project arra
                      let newProjectsArray = [...activeInvoice.projectIds!];
                      newProjectsArray.push("");

                      // New allocatino
                      let newAllocations = [...activeInvoice.partition!];
                      newAllocations.push('0');

                      console.log(newProjectsArray);
                      console.log(newAllocations);
                      // UPdate state
                      setActiveInvoice({
                        ...activeInvoice,
                        projectIds: newProjectsArray,
                        partition: newAllocations,
                      });
                      setProjectMismatchFlag(true);
                    }}
                    onSelectProject={(index, id) => {
                      // Copy current state
                      let newProjectIds = [...activeInvoice!.projectIds!];
                      // Update id number
                      newProjectIds[index] = id;
                      // Udpagte state
                      setActiveInvoice({
                        ...activeInvoice,
                        projectIds: newProjectIds,
                      });
                    }}
                    updateAllocation={(index, amount) => {
                      activeInvoice.partition![index] = amount;
                      setActiveInvoice({
                        ...activeInvoice,
                        partition: [...activeInvoice.partition!],
                      });
                    }}
                    onRemove={(index) => removePartition(index)}
                  />
                }
                {/* Document */}
                <IonCol>
                  <DocumentInput
                    label="Document"
                    hideHeader={true}
                    value={activeInvoice.document !== null ? activeInvoice.document : undefined}
                    onUpload={(key) =>
                      setActiveInvoice({ ...activeInvoice, document: key })
                    }
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </>
      )}
    </IonCard>
  );
};
